import React, { useEffect, useState } from "react";
import { Button, Input, Row, Col } from "reactstrap";
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import { colourStyles4 } from "../../utils/react_select_styles";
import { Switch, Drawer, message, Upload, Button as AntdButton, Form, Input as AntdInput } from 'antd';
import { InboxOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import general_fetch from "../../utils/fetch";
import BackOfficeTickets from "../BackOfficeTickets";
import {impact_area_options} from "../../utils/cs_ticket_impact_area";
const { Dragger } = Upload;

const department_options = [{ value: 'none', label: 'None' }, { value: 'customer_success', label: 'Customer Success Team' }, { value: 'product', label: 'Product Team' }, { value: 'catalogue', label: 'Catalogue Team' }]

const AddCustomerTicket = ({ open, toggle, mode, active_ticket, cs_org_users, user_details, fetch_customer_tickets, all_orgs, set_page_loader, current_org }) => {

	const [step, set_step] = useState(0);
	const [title, set_title] = useState();
    const [impact_area, set_impact_area] = useState();
	const [org_id, set_org_id] = useState();
	const [priority, set_priority] = useState();
	const [customer_priority, set_customer_priority] = useState();
	const [client_notification, set_client_notification] = useState(false);
	const [auto_close, set_auto_close] = useState(true);
	// const [department, set_department] = useState('none');
    const department = 'product';
	const [description, set_description] = useState();
	const [link_with_existing_ticket, set_link_with_existing_ticket] = useState(true);
	const [assignee_id, set_assignee_id] = useState();
	const [type, set_type] = useState();
	// const [use_case, set_use_case] = useState();
	// const [reproduction, set_reproduction] = useState();
	const [design_link, set_design_link] = useState();
	const [drive_links, set_drive_links] = useState(['']);
	// const [comments, set_comments] = useState();
	const [files, set_files] = useState([]);
	// const [back_office_ticket_id_for_linking, set_back_office_ticket_id_for_linking] = useState();

	const props = {
		name: 'file',
		multiple: true,
		action: '',
		customRequest: ({ fileList, onSuccess }) => { onSuccess() },
		onChange(info) {
			const { status } = info.file;
			set_files(info.fileList)

			console.log(info.fileList)
			//   if (status !== 'uploading') {
			// 	console.log(info.file, info.fileList);
			//   }
			//   if (status === 'done') {
			// 	message.success(`${info.file.name} file uploaded successfully.`);
			//   } else if (status === 'error') {
			// 	message.error(`${info.file.name} file upload failed.`);
			//   }
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

	const onclick_handleclose = () => {
		toggle()
		set_step(0)
		set_title('')
        set_impact_area()
		set_org_id()
		set_priority()
		set_customer_priority()
		set_client_notification(true)
		// set_department('none')
		set_description()
		set_link_with_existing_ticket(true)
		set_assignee_id()
		set_type()
		/* set_use_case()
		set_reproduction() */
		set_design_link()
		// set_comments()
	}

	const check_next_button = () => {
		let go_to_next_step = 0
		if (step === 0 && title && impact_area) {
			go_to_next_step = 1
		} else if (step != 0) {
			go_to_next_step = 1
		}

		if (go_to_next_step) {
			set_step(step + 1)
		} else {
			toastr.error('Please enter mandatory fields')
		}

	}

	const submit_fn = async () => {
		try {
			let fd = new FormData();
			fd.append('title', title)
			if(org_id) fd.append('org_id', org_id)
            if(impact_area) fd.append('impact_area', impact_area)
			if(priority) fd.append('priority', priority)
			// if(customer_priority) fd.append('customer_priority', customer_priority)
			if(!client_notification) fd.append('client_notification', client_notification)
			if(!auto_close) fd.append('auto_close', auto_close)
			if(description) fd.append('description', description)
			// if(assignee_id) fd.append('assignee_id', assignee_id)
			if(type) fd.append('type', type)
			// if(department) fd.append('department', department)
			// if(back_office_ticket_id_for_linking) fd.append('existing_back_office_ticket_id', back_office_ticket_id_for_linking)
			if (design_link && design_link.split('/') && design_link.split('/').length > 4) {
				if(design_link.split('/')[4].includes(' ') || design_link.split('/')[4].includes('https')){
					toastr.error('Design Link is invalid')
					return
				}
				fd.append('design_branch_id', design_link.split('/')[4])
			}
			// let additional_info = { use_case, reproduction }
			// fd.append('additional_info', JSON.stringify(additional_info))

			if(drive_links && drive_links.length){
				let temp_drive_links = drive_links.filter(o => o)
				if(temp_drive_links && temp_drive_links.length){
					fd.append('drive_links', JSON.stringify(temp_drive_links))
				}
			}

			if (files && files.length) {
				for (let file of files) {
					fd.append('files', file.originFileObj)
				}
			}

			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({ url: 'customer_ticket/create', body: fd, is_form_data: true })
			toastr.success('Successfully created customer ticket')
			set_page_loader(false)
			fetch_customer_tickets()
			onclick_handleclose()
		} catch (e) {
			set_page_loader(false)
			console.error(e)
			toastr.error(`Couldn't ${mode === 'add' ? 'Create' : 'Update'} Ticket`)
		}
	}

	const get_assignee_list = () => {
		if(cs_org_users && cs_org_users.length){
			let temp_cs_org_users = JSON.parse(JSON.stringify(cs_org_users))
			if(department === 'customer_success'){
				temp_cs_org_users = temp_cs_org_users.filter(o => o.role.includes('cs_rep'))
			}else if(department === 'product'){
				temp_cs_org_users = temp_cs_org_users.filter(o => o.role.includes('infurnia_product_manager'))
			}else if(department === 'catalogue'){
				temp_cs_org_users = temp_cs_org_users.filter(o => o.role.includes('infurnia_catalog_admin'))
			}
			return temp_cs_org_users.map(o => { return { value: o.id, label: o.first_name } }) 
		}
		return []
	}

	useEffect(() => {
		if (department === 'catalogue') {
			set_type('org')
		} else {
			set_type('')
		}
	}, [department]);

	useEffect(() => {
		console.log('filess', files)
	}, [files]);

	const Description = () => (
		<div className="mb-4">
			<div className='mb-2 bold'>Description</div>
			<textarea style={{ height: '200px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1' value={description} onChange={(e) => set_description(e.target.value)} placeholder='Type...' />
		</div>
	)

	const AssignedTo = () => (
		<div className="mb-4">
			<div className='mb-2 bold'>Assigned to</div>
			<Select
				// closeMenuOnSelect={false}
				// components={animatedComponents}
				value={{ value: assignee_id, label: cs_org_users && cs_org_users.length && assignee_id ? cs_org_users.find(o => o.id === assignee_id).first_name : '' }}
				// isMulti
				options={get_assignee_list()}
				styles={colourStyles4}
				onChange={(selectedOptions) => set_assignee_id(selectedOptions.value)}
			/>
		</div>
	)

	const UploadFiles = () => (
		<Dragger {...props} style={{ display: 'block' }}>
			<p className="ant-upload-drag-icon">
				<InboxOutlined />
			</p>
			<p className="ant-upload-text">Click or drag file to this area to upload</p>
			<p className="ant-upload-hint">
				Supports a single or bulk upload.
			</p>
		</Dragger>
	)

	const DriveLinks = () => (
		<div className="flex_center mb-4" style={{flexDirection:'column'}}>
		{
			drive_links && drive_links.length ? drive_links.map((link,idx) => (
				<div className="flex_property" style={{width:'100%'}}>
					<Input key={Math.random()} type='text' style={{ height: '36px' }} className='px-2 py-1 mb-2' placeholder={'Type...'} defaultValue={link} onChange={(e) => {drive_links[idx]=e.target.value; console.log('drive_links', drive_links)}} />
					{
						drive_links && drive_links.length>1 ?
						<MinusCircleOutlined className="ml-2 cp" onClick={() => set_drive_links(drive_links.filter((o, index) => index != idx))} />:""
					}
				</div>
			)):''
		}
		<AntdButton type="dashed" className="flex_center" onClick={() => set_drive_links([...drive_links, ''])}><PlusOutlined /> Add Link</AntdButton>
		</div>
	)

	return (
		<Drawer open={open} onClose={onclick_handleclose} size="large" title='Create Customer Ticket' >
			{/* <CspModalHeader title={(mode==='add' ? 'Create' : 'Update') + ' Customer Ticket'} toggle={onclick_handleclose}/> */}
			{/* <CspModalBody style={{maxHeight:'600px'}}> */}
			{
				step === 0 ?
					<>
                        <Row>
                            <Col md={8}>
                                <div className='mb-2 bold'>Title*</div>
                                <Input type='text' style={{ height: '36px' }} className='px-2 py-1 mb-4' placeholder={'Type...'} value={title} onChange={(e) => set_title(e.target.value)} />
                            </Col>
                                <Col md={4}>
                                    <div className='mb-2 bold'>Impact Area*</div>
                                    <div style={{ zIndex: 999999999, position: 'relative' }}>
                                        <Select
                                            value={{ value: impact_area, label: impact_area }}
                                            options={impact_area_options}
                                            onChange={(selectedOptions) => set_impact_area(selectedOptions.value)}
                                        />
                                    </div>
                                </Col>
                        </Row>
						<div className='mb-2 bold'>Org</div>
						<div className="mb-4">
							{
								current_org ?
									<Input type='text' style={{ height: '36px' }} className='px-2 py-1' value={current_org.name} readOnly /> :
									<Select
										// closeMenuOnSelect={false}
										// components={animatedComponents}
										value={{ value: org_id, label: all_orgs && all_orgs.length && all_orgs.find(o => o.id === org_id) ? all_orgs.find(o => o.id === org_id).name : '' }}
										// isMulti
										options={all_orgs && all_orgs.length ? all_orgs.map(x => { return { value: x.id, label: (x.name + ' (' + x.id + ')') } }) : []}
										onChange={(selectedOptions) => set_org_id(selectedOptions.value)}
									/>
							}
						</div>

						<div className='mb-4 bold'>Priority (to be assigned by CS). 1 is highest priority, 5 is lowest</div>
						<div className="flex_property mb-4" style={{ fontWeight: 400 }}>
							<div>
								<div style={{ minHeight: '32px' }}></div>
								{/* <div style={{ minHeight: '32px', fontSize: '12px' }}>Highest Priority</div> */}
							</div>
							{[1, 2, 3, 4, 5].map(x => (
								<div>
									<div style={{ minHeight: '32px' }} className="ml-2 mr-2">{x}</div>
									<div style={{ minHeight: '32px' }} className="ml-2 mr-2" onClick={() => set_priority(x)} >
										<input type="radio" checked={priority === x ? true : false} />
									</div>
								</div>
							))}
							<div>
								<div style={{ minHeight: '32px' }}></div>
								{/* <div style={{ minHeight: '32px', fontSize: '12px' }}>Lowset Priority</div> */}
							</div>
						</div>
                        {
                            // <>
                            //     <div className='mb-4 bold'>Customer priority or Client misery level (1 is highest priority, 5 is lowest)</div>
                            //     <div className="flex_property mb-4" style={{ fontWeight: 400 }}>
                            //         <div>
                            //             <div style={{ minHeight: '32px' }}></div>
                            //             {/* <div style={{ minHeight: '32px', fontSize: '12px' }}>High priority for customer</div> */}
                            //         </div>
                            //         {[1, 2, 3, 4, 5].map(x => (
                            //             <div>
                            //                 <div style={{ minHeight: '32px' }} className="ml-2 mr-2">{x}</div>
                            //                 <div style={{ minHeight: '32px' }} className="ml-2 mr-2" onClick={() => set_customer_priority(x)} >
                            //                     <input type="radio" checked={customer_priority === x ? true : false} />
                            //                 </div>
                            //             </div>
                            //         ))}
                            //         <div>
                            //             <div style={{ minHeight: '32px' }}></div>
                            //             {/* <div style={{ minHeight: '32px', fontSize: '12px' }}>Low priority for customer</div> */}
                            //         </div>
                            //     </div>
                            // </>
                        }
                        {
                            /* <div className='mb-2 bold'>Dependency</div>
                        <div className="mb-4">
                            <Select
                                // closeMenuOnSelect={false}
                                // components={animatedComponents}
                                value={department ? { value: department, label: department_options.find(o => o.value === department).label } : {}}
                                // isMulti
                                options={department_options}
                                // onChange={(selectedOptions) => set_department(selectedOptions.value)}
                            />
                            </div> */
                        }
						{
							/* department != 'none'?
							<div className="flex_property mb-4">
								<Switch size="small" className="mr-2" value={auto_close} onClick={() => set_auto_close(!auto_close)}/>
								<div>Automatically <span className="bold">mark as done</span> once the dependency Product ticket is marked as done.</div>
							</div>:"" */
						}
					</> : (
						// step === 1 ?
							<>
								{
									// department === 'none' ?
										<>
											{Description()}
											<div className='mb-2 bold'>Upload files</div>
											{UploadFiles()}
											<div className='mb-2 bold'>Drive/Sheets/Docs Links</div>
											{DriveLinks()}
										</> 
                                        /* :
										<>
											<div className="mb-2 bold">Select one option</div>
											<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_link_with_existing_ticket(true) }} >
												<input type="radio" className="mr-2" checked={link_with_existing_ticket ? true : false} />
												<div>Link this ticket with an existing Back-office ticket</div>
											</div>
											<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 mb-4 flex_property" onClick={() => { set_link_with_existing_ticket(false) }} >
												<input type="radio" className="mr-2" checked={!link_with_existing_ticket ? true : false} />
												<div>Create new Back-office ticket</div>
											</div>
										</> */
								}
							</> 
                            /* : (
								step === 2 && !link_with_existing_ticket ?
									<>
										{
											department === 'customer_success' ?
												<>
													{AssignedTo()}
													{Description()}
													<div className='mb-2 bold'>Upload files</div>
													{UploadFiles()}
													<div className='mb-2 bold'>Drive/Sheets/Docs Links</div>
													{DriveLinks()}
												</> : (
													department === 'product' ?
														<>
															<label className="form-control-label mt-2 mb-4 flex_property" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
																<div className='mr-2'>Type</div>
																<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('feature') }} >
																	<input type="radio" className="mr-2" checked={type === 'feature' ? true : false} />
																	<div className="mr-4">Feature</div>
																</div>
																<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('bug') }} >
																	<input type="radio" className="mr-2" checked={type === 'bug' ? true : false} />
																	<div>Bug</div>
																</div>
															</label>
															{
																type === 'feature' || type === 'bug' ? AssignedTo() : ""
															}
															{
																type === 'feature' ?
																	<>
																		<div className='mb-2 bold'>Use Case</div>
																		<textarea style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={use_case} onChange={(e) => set_use_case(e.target.value)} placeholder='e.g. The client is trying to create shutters with a design that is shown in this image link <url>' />
																		{Description()}
																		<div className='mb-2'>Upload files</div>
																		{UploadFiles()}
																		<div className='mb-2 bold'>Drive/Sheets/Docs Links</div>
																		{DriveLinks()}
																	</> : (
																		type === 'bug' ?
																			<>
																				<div className='mb-2 bold'>Reproduction</div>
																				<textarea style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={reproduction} onChange={(e) => set_reproduction(e.target.value)} placeholder='e.g. Add a Cabinet, Change the shutter and external finish and then change shutter design from normal to profile' />
																				<div className='mb-2'>Design Link</div>
																				<div className="flex_property mb-4" style={{ width: '100%' }}>
																					<Input type='text' style={{ height: '36px', width: '60%' }} className='px-2 py-1 mr-2' placeholder={'Type...'} value={design_link} onChange={(e) => set_design_link(e.target.value)} />
																					{
                                                                                        // {org_id ? <Button color='secondary'>Add "pm1" to the Org</Button> : ''}
                                                                                    }
																				</div>
																				<div className='mb-2 bold'>Upload files</div>
																				{UploadFiles()}
																				<div className='mb-2 bold'>Drive/Sheets/Docs Links</div>
																				{DriveLinks()}
																				{
                                                                                    // <div className='mb-2 bold'>Comments</div>
                                                                                    // <textarea style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={comments} onChange={(e) => set_comments(e.target.value)} placeholder='e.g. the same shutter related bug was also noticed at partition level also' />
                                                                                }
																			</> : ""
																	)
															}
														</> :
														<>
															{AssignedTo()}
															<label className="form-control-label mt-2 " htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
																<div className='mr-2 bold'>Global/Org</div>
																<div className="flex_property mb-4">
																	<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('org') }} >
																		<input type="radio" className="mr-2" checked={type === 'org' ? true : false} />
																		<div className="mr-4">Org</div>
																	</div>
																	<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('global') }} >
																		<input type="radio" className="mr-2" checked={type === 'global' ? true : false} />
																		<div className="mr-4">Global</div>
																	</div>
																	<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('org_and_global') }} >
																		<input type="radio" className="mr-2" checked={type === 'org_and_global' ? true : false} />
																		<div>Both</div>
																	</div>
																</div>
															</label>
															{Description()}
															<div className='mb-2 bold'>Upload files</div>
															{UploadFiles()}
															<div className='mb-2 bold'>Drive/Sheets/Docs Links</div>
															{DriveLinks()}
														</>
												)
										}
									</> :
									<>
                                        {
                                            // <BackOfficeTickets cs_org_users={cs_org_users} all_stores={all_orgs} link_mode={true} link_mode_department={department} back_office_ticket_id_for_linking={back_office_ticket_id_for_linking} set_back_office_ticket_id_for_linking={set_back_office_ticket_id_for_linking} user_details={user_details}/>
                                            // <div style={{position:"absolute", bottom:'8px', width:'95%', height:'40px', backgroundColor:"white"}} className="flex_between p-2">
                                            // <Button color='primary' onClick={() => set_step(step - 1)}>Go Back</Button>
                                            // <Button color='primary' disabled={!back_office_ticket_id_for_linking} onClick={submit_fn}>Link Product Ticket & Submit</Button>
                                            // </div>:""
                                        }
									</>
							) */
					)
			}
			{/* </CspModalBody> */}
			{/* <CspModalFooter> */}
			{
                // step > 0 && !(step==2 && link_with_existing_ticket) ? <Button color='primary' onClick={() => set_step(step - 1)}>Back</Button> : ''
                step === 1 ? <Button color='primary' onClick={() => set_step(step - 1)}>Back</Button> : ''
            }
			{
                // step < 2 && !(step === 1 && department === 'none') ? <Button color='primary' onClick={check_next_button}>Next</Button> : ''
                step === 0 ? <Button color='primary' onClick={check_next_button}>Next</Button> : ''
            }
			{
                // (step === 2 && !link_with_existing_ticket) || (step === 1 && department === 'none') ? <Button color='primary' onClick={submit_fn}>Submit</Button> : ''
                step === 1 ? <Button color='primary' onClick={submit_fn}>Submit</Button> : ''
            }
			{/* </CspModalFooter> */}
		</Drawer>
	)
}

export default AddCustomerTicket;