import Header from "../../components/Headers/Header";
import React, { useEffect, useState } from "react";
import {toastr} from 'react-redux-toastr';
import { Button, Card, CardHeader, Col, Container, FormGroup, Input, Modal, Row, Table } from "reactstrap";
import Select from "react-select";
import general_fetch from "../../utils/fetch";
import language_list from "../../utils/language_list";
import { convert_to_readable } from "../../utils/convert_to_readable";
import { Tooltip } from "antd";
import { date_string } from "../../utils/date_string";
import { infurnia_add_ons } from "../../utils/infurnia_add_ons";
import NA from "../../components/NA";
import fetch_mix_panel_data from "../../utils/fetch_mixpanel_data";

const styles = {
	header_row: {
		marginLeft: 0,
	},
	table_header: {
		fontWeight: "bold",
		fontSize: 'small'
	},
	round_status: {
		fontSize: 'small',
		paddingBottom: 0
	},
	nav_pills_rounds: {
		paddingRight: 60,
		marginRight: "0.7rem",
		marginLeft: "0.2rem",
	},
	action_button: {
		margin: 1       
	}
};

const infurnia_domains = [{value: 'interior', label: 'Interiors'}, {value: 'architecture', label: 'Architecture'}]

// const AddCsExecutive = ({open, toggle, users}) => {

	

// 	const onClick_handleClose = () => {
// 		toggle()
// 	}

// 	return(
// 		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onClick_handleClose}>
// 			<div className="modal-header  bg-gradient-white">
// 				<h4 className="modal-title" style={{}}>Add CS Executive</h4>
// 				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onClick_handleClose}>
// 				<span aria-hidden={true}>×</span>
// 				</button>
// 			</div>
// 			<div className="modal-body  bg-gradient-secondary" style={{fontSize:'14px'}}>
// 				<Row>
                    
//                   </Row>
// 			</div>
// 			<div className="modal-footer" style={{height:'60px'}}>
// 				<Button color='primary'>Submit</Button>
// 			</div>
// 		</Modal>
// 	)
// }

const EditCsExecutive = ({open, toggle, active_cs, geographies, mode, remaining_cs_users, fetch_cs_users}) => {

	const [selected_user, set_selected_user] = useState([]);

	const [selected_geographies, set_selected_geographies] = useState([]);
	const [selected_domains, set_selected_domains] = useState();
	const [selected_languages, set_selected_languages] = useState([]);
	const [selected_expertise, set_selected_expertise] = useState([]);
	const [start_time, set_start_time] = useState('4:30');
	const [end_time, set_end_time] = useState('13:30');
	
	const infurnia_add_ons = [{value:'design', label:'Design'}, 
								{value:'manufacturing', label:'Manufacturing'}, 
								{value:'api_integration', label:'API Integration'}, 
								{value:'white_labeling', label:'White Labeling'}, 
								{value:'panel_cut_list', label:'Panel Cut-List'}, 
								{value:'pricing_setup', label:'Advanced Pricing'}, 
								{value:'mes_lite', label:'MES Lite'}, 
								{value:'mes_pro', label:'MES Pro'},
							]

	const add_update_cse = async() => {
		try{
			let domain, geographies, add_ons, languages
			if(mode === 'add' && !selected_user.value){
				toastr.error('Please select a user')
				return
			}

			if(!selected_domains.value){
				toastr.error('Please select Domains')
				return
			}else{
				domain = selected_domains.value
			}

			if(!(selected_expertise && selected_expertise.length)){
				toastr.error('Please select Expertise')
				return
			}else{
				add_ons = selected_expertise.map(x => x.value)
			}

			if(!(selected_languages && selected_languages.length)){
				toastr.error('Please select Languages')
				return
			}else{
				languages = selected_languages.map(x => x.value)
			}

			if(!(selected_geographies && selected_geographies.length)){
				toastr.error('Please select geographies')
				return
			}else{
				geographies = selected_geographies.map(x => x.value)
			}

			if(!start_time || !end_time || !(start_time.split(':') && start_time.split(':').length==2) || !(end_time.split(':') && end_time.split(':').length==2)){
				toastr.error('Start/end time is invalid')
			}
			let [start_time_hrs, start_time_minutes] = start_time.split(':').map(Number)
			let start_time_offset_in_min = start_time_hrs * 60 + start_time_minutes 

			let [end_time_hrs, end_time_minutes] = end_time.split(':').map(Number)
			let end_time_offset_in_min = end_time_hrs * 60 + end_time_minutes 


			let body = {languages, add_ons, geographies, domain, start_time_offset_in_min, end_time_offset_in_min}
			if(mode === 'add'){
				body['cs_user_id'] = selected_user.value
			}else{
				body['id'] = active_cs.id
			}
			if(mode === 'add'){
				let resp = await general_fetch({url:'customer_success_executive/create', body})
			}else{
				let resp = await general_fetch({url:'customer_success_executive/update', body})
			}
			fetch_cs_users()
			toastr.success(`Successfully ${mode==='add' ? 'added' : 'updated'} CS Rep`)
			onClick_handleClose()
		}catch(err){
			console.error(err);
			toastr.error(`Couldn't ${mode==='add' ? 'add' : 'update'} CS Rep`)
		}
	}

	const onClick_handleClose = () => {
		set_selected_domains('')
		set_selected_expertise([])
		set_selected_geographies([])
		set_selected_languages([])
		set_selected_user('')
		toggle()
	}

	useEffect(() => {
		if(active_cs && mode==='edit'){
			if(active_cs.domain){
				set_selected_domains(infurnia_domains.find(x => x.value === active_cs.domain))
			}
			if(active_cs.expertise && active_cs.expertise.length){
				set_selected_expertise(active_cs.expertise.map(x => infurnia_add_ons.find(o => o.value === x)))
			}
			if(active_cs.languages && active_cs.languages.length){
				set_selected_languages(active_cs.languages.map(x => {let lan = language_list.find(o => o.code === x); return {value: lan.code, label: lan.name}}))
			}
			if(active_cs.geographies && active_cs.geographies.length){
				set_selected_geographies(active_cs.geographies.map(x => {let geo = geographies.find(o => o.id === x); return {value: geo.id, label: geo.name}}))
			}
			if(active_cs.start_time_offset_in_min){
				let hrs = Math.floor(active_cs.start_time_offset_in_min/60)
				let min = active_cs.start_time_offset_in_min%60
				if(min == 0) min += '0'
				set_start_time(hrs + ':' + min)
			}
			if(active_cs.end_time_offset_in_min){
				let hrs = Math.floor(active_cs.end_time_offset_in_min/60)
				let min = active_cs.end_time_offset_in_min%60
				if(min == 0) min += '0'
				set_end_time(hrs + ':' + min)
			}
		}
	}, [active_cs, mode]);

	return (
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onClick_handleClose}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" style={{}}>{mode==='add' ? 'Add' : 'Update'} CS Rep </h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onClick_handleClose}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{ fontSize: '14px' }}>
				<Row>
					{
						mode === 'add'?
						<Col md="12">
							<FormGroup>
								<label className="form-control-label" htmlFor="input-address" >
									Select User
								</label>
								{/* <Input name="credits" className="form-control-alternative" type="select" placeholder='Type...' value={language} onChange={(e) => set_language(e.target.value)} /> */}
								<Select
									// closeMenuOnSelect={false}
									// components={animatedComponents}
									value = {selected_user}
									// isMulti
									options={remaining_cs_users && remaining_cs_users.map(c => {return {'value': c.id, 'label': c.first_name}})}
									// styles={colourStyles}
									onChange={(selectedOptions) => set_selected_user(selectedOptions)}
								/>
							</FormGroup>
						</Col>:""
					}
					<Col md="12">
						<FormGroup>
							<div style={{position:'relative', zIndex:'5'}}>
								<label className="form-control-label" htmlFor="input-address" >
									Select Domain
								</label>
								{/* <Input name="credits" className="form-control-alternative" type="select" placeholder='Type...' value={language} onChange={(e) => set_language(e.target.value)} /> */}
								<Select
									// closeMenuOnSelect={false}
									// components={animatedComponents}
									value={selected_domains}
									// isMulti
									options={[{value: 'interior', label: 'Modular Interiors'}, {value: 'architecture', label: 'Architecture & Interiors'}]}
									// styles={colourStyles}
									onChange={(selectedOptions) => set_selected_domains(selectedOptions)}
								/>
							</div>

							<div style={{position:'relative', zIndex:'4'}}>
								<label className="form-control-label mt-3" htmlFor="input-address" >
									Select Expertise
								</label>
								{/* <Input name="credits" className="form-control-alternative" type="select" placeholder='Type...' value={language} onChange={(e) => set_language(e.target.value)} /> */}
								<Select
									closeMenuOnSelect={false}
									// components={animatedComponents}
									value={selected_expertise}
									isMulti
									options={infurnia_add_ons}
									// styles={colourStyles}
									onChange={(selectedOptions) => set_selected_expertise(selectedOptions)}
								/>
							</div>

							<div style={{position:'relative', zIndex:'3'}}>
								<label className="form-control-label mt-3" htmlFor="input-address" >
									Select Language
								</label>
								{/* <Input name="credits" className="form-control-alternative" type="select" placeholder='Type...' value={language} onChange={(e) => set_language(e.target.value)} /> */}
								<Select
									closeMenuOnSelect={false}
									// components={animatedComponents}
									value={selected_languages}
									isMulti
									options={language_list && language_list.map(c => { return { 'value': c.code, 'label': c.name } })}
									// styles={colourStyles}
									onChange={(selectedOptions) => set_selected_languages(selectedOptions)}
								/>
							</div>

							<div style={{position:'relative', zIndex:'2'}}>
								<label className="form-control-label mt-3" htmlFor="input-address" >
									Select Geography
								</label>
								{/* <Input name="credits" className="form-control-alternative" type="select" placeholder='Type...' value={language} onChange={(e) => set_language(e.target.value)} /> */}
								<Select
									closeMenuOnSelect={false}
									// components={animatedComponents}
									value={selected_geographies}
									isMulti
									options={geographies && geographies.length ? geographies.map(c => { return { 'value': c.id, 'label': c.name } }) : []}
									// styles={colourStyles}
									onChange={(selectedOptions) => set_selected_geographies(selectedOptions)}
								/>
							</div>
							<div style={{position:'relative', zIndex:'1'}}>
								<label className="form-control-label mt-3" htmlFor="input-address" >
									Availability for Onboarding (in GMT, 24 hr format HH:mm)
								</label>
								{/* <TimePicker.RangePicker 
									format = 'HH:mm' 
									// defaultOpenValue={'10:00'}
									onChange={(vals) => setValues(vals)}
									value={[valus[0], valus[1]]}	
								/> */}
								<div className="flex_property">
									<Input className='standard_input' value={start_time} onChange={(e) => set_start_time(e.target.value)} placeholder = 'HH:mm' />
									<div className="mx-4">to</div>
									<Input className='standard_input' value={end_time} onChange={(e) => set_end_time(e.target.value)} placeholder = 'HH:mm'/>
								</div>
							</div>
                      </FormGroup>
                    </Col>
                  </Row>
			</div>
			<div className="modal-footer" style={{height:'60px'}}>
				<Button color='primary' onClick={add_update_cse}>Submit</Button>
			</div>
		</Modal>
	)
}

const CsLoad = ({open, toggle, active_cs, all_stores, mixpanel_org_level_events}) => {

	const [cs_orgs, set_cs_orgs] = useState([]);
	const [sorted_asc_by_json, set_sorted_asc_by_json] = useState({});
	const [selected_usage_events, set_selected_usage_events] = useState([{ value: 'version_save', label: 'Version Save' }]);

	const load_sort = (a, b, order) => {
		if(!a.load) return -1*order
		if(!b.load) return 1*order
		if(Number(a.load) > Number(b.load)){
			return 1*order
		}else{
			return -1*order
		}
	}

	const sort_by_load = () => {
		let temp_orgs = JSON.parse(JSON.stringify(cs_orgs))
		temp_orgs.sort((a,b) => load_sort(a,b,!sorted_asc_by_json['sorted_by_load'] ? 1 : -1));
		sorted_asc_by_json['sorted_by_load'] = !sorted_asc_by_json['sorted_by_load']
		set_cs_orgs(temp_orgs)
	}

	const return_add_ons_string = (add_on_ids) => {
		let str = ''
		if(add_on_ids && add_on_ids.length){
			add_on_ids = add_on_ids.map((x, idx) => infurnia_add_ons.find(o => o.value === x)?infurnia_add_ons.find(o => o.value === x).label:'').sort()
			add_on_ids.map((x, idx) => str = str + (idx!=0 ? ', ' : '') + x, '')
		}
		return str
	}

	useEffect(() => {
		if(active_cs && all_stores && all_stores.length){
			if(active_cs.stores && active_cs.stores.length){
				for(let i = 0; i < active_cs.stores.length; i++){
					let store = active_cs.stores[i]
					store.details = all_stores.find(o => o.id === store.store_id)
				}
				set_cs_orgs(active_cs.stores)
			}
		}
	}, [active_cs, all_stores]);

	return (
		<Modal className="modal-dialog-centered" size="xl" isOpen={open} toggle={toggle}>
			<div className="modal-header  bg-gradient-white">
				<h4 className="modal-title" style={{}}>CS Load </h4>
				<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
					<span aria-hidden={true}>×</span>
				</button>
			</div>
			<div className="modal-body  bg-gradient-secondary" style={{ fontSize: '14px', maxHeight:'600px', overflow:'scroll'}}>
				<Table className="align-items-center table-flush text-center" responsive>
					<thead className="thead-light text-center">
						<tr style={{ textAlign: 'left' }}>
							<th scope="col"><span style={styles.table_header}><b>S.No</b></span>&nbsp;</th>
							<th scope="col"><span style={styles.table_header}><b>Org Name</b></span>&nbsp;</th>
							<th scope="col"><span style={styles.table_header}><b>Load <i className="fa fa-sort" onClick={() => sort_by_load()} /></b></span>&nbsp;</th>
							<th scope="col"><span style={styles.table_header}><b>Onboarding Status</b></span>&nbsp;</th>
							<th scope="col"><span style={styles.table_header}><b>Start Date</b></span>&nbsp;</th>
							<th scope="col"><span style={styles.table_header}><b>7 Day Usage</b></span>&nbsp;</th>
							<th scope="col"><span style={styles.table_header}><b>Responsive</b></span>&nbsp;</th>
							<th scope="col"><span style={styles.table_header}><b>Add-ons</b></span>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						{
							cs_orgs && cs_orgs.length ? cs_orgs.map((org, idx) => (
								<tr className="display_onhover" style={{ textAlign: 'left' }}>
									<td scope="col"><span>{idx + 1}</span>&nbsp;</td>
									<td scope="col"><a href={"/home/store_dashboard/" + org.store_id + '/7'} target="_blank">{org.details.name}</a>&nbsp;</td>
									<td scope="col" className="text-right"><span>{org.load}</span>&nbsp;</td>
									<td scope="col"><span>{convert_to_readable(org.details.onboarding_status)}</span>&nbsp;</td>
									<td scope="col"><span>{date_string(org.details.license_start_date)}</span>&nbsp;</td>
									<td scope="col" className="text-right"><span>{mixpanel_org_level_events && mixpanel_org_level_events[org.store_id] && selected_usage_events.length ? selected_usage_events.reduce((final, event) => mixpanel_org_level_events[org.store_id][event.value] ? final + mixpanel_org_level_events[org.store_id][event.value] : final, 0).toLocaleString() : "0"}</span>&nbsp;</td>
									<td scope="col"><span>{org.details.responsive ? 'Yes' : 'No'}</span>&nbsp;</td>
									<td scope="col"><span>{org.details.active_add_on_ids && org.details.active_add_on_ids.length ? return_add_ons_string(org.details.active_add_on_ids) : <NA/>}</span>&nbsp;</td>
								</tr>
							)) : ''
						}
					</tbody>
				</Table>
			</div>
			<div className="modal-footer" style={{height:'60px'}}>
				<Button color='primary' onClick={toggle}>Close</Button>
			</div>
		</Modal>
	)
}

const CSExecutives = ({user_details, cs_org_users, all_stores, fetch_infurnia_cs_reps, is_cs_admin, is_team_lead, infurnia_teams}) => {

	const [add_modal_open, set_add_modal_open] = useState(false);
	const [remaining_cs_users, set_remaining_cs_users] = useState([]);
	const [non_cs_users, set_non_cs_users] = useState([]);
	const [edit_modal_open, set_edit_modal_open] = useState(false);
	const [active_cs, set_active_cs] = useState('');
	const [geographies, set_geographies] = useState([]);
	const [added_cs_users, set_added_cs_users] = useState([]);
	const [mode, set_mode] = useState('');
	const [show_cs_load_modal, set_show_cs_load_modal] = useState(false);
	const [sorted_asc_by_json, set_sorted_asc_by_json] = useState({});
	const [mixpanel_org_level_events, set_mixpanel_org_level_events] = useState();
	const toggle_edit_modal = () => set_edit_modal_open(!edit_modal_open)
	const toggle_add_modal = () => set_add_modal_open(!add_modal_open)
	
	const fetch_cs_org_users = async () => {
		try {
			let account_details = cs_org_users
			// if (account_details && account_details.length) {
			// 	// account_details = account_details.filter(x => !x.depricated && (x.business_unit_info && x.business_unit_info[user_details.current_business_unit_id].find(x => x.role_id == 'customer_success_admin') || x.business_unit_info && x.business_unit_info[user_details.current_business_unit_id].find(x => x.role_id == 'customer_success_executive')))
			// 	let account_details_cs = account_details.filter(x => !x.depricated && (x.role.includes('customer_success_admin') || x.role.includes('customer_success_executive')))
			// 	set_cs_users(account_details_cs)
			// 	let account_details_non_cs = account_details.filter(x => !x.depricated && !(x.role.includes('customer_success_admin') || x.role.includes('customer_success_executive')))
			// 	set_non_cs_users(account_details_non_cs)
			// }
			let resp = await general_fetch({url:'customer_success_executive/get'})
			
			if(resp && resp.length){
				for(let i=0;i<resp.length;i++){
					let user = account_details.find(o => o.id === resp[i].user_id)
					resp[i].first_name = user ? user.first_name : ''
					resp[i].email = user? user.email : ''
				}
				set_added_cs_users(resp)
			}

			account_details = account_details.filter(x => !resp.find(o => o.user_id === x.id))
			set_remaining_cs_users(account_details)
			fetch_infurnia_cs_reps()//this is to refetch in admin.jsx
		} catch (err) {
			console.error(err)
		}
	}

	const fetch_geography = async() => {
		try{
            var resp = await general_fetch({url: 'geography/get'});
			set_geographies(resp)
		}catch(err){
			console.log(err)
		}
	}

	const load_sort = (a, b, order) => {
		if(!a.load) return -1*order
		if(!b.load) return 1*order
		if(Number(a.load) > Number(b.load)){
			return 1*order
		}else{
			return -1*order
		}
	}

	const sort_by_load = () => {
		let cs_reps = JSON.parse(JSON.stringify(added_cs_users))
		cs_reps.sort((a,b) => load_sort(a,b,!sorted_asc_by_json['sorted_by_load'] ? 1 : -1));
		sorted_asc_by_json['sorted_by_load'] = !sorted_asc_by_json['sorted_by_load']
		set_added_cs_users(cs_reps)
	}

	useEffect(() => {
		if(cs_org_users && cs_org_users.length){
			fetch_cs_org_users()
			fetch_geography()
		}
	}, [cs_org_users]);

	useEffect(() => {
		if(user_details && is_team_lead && !is_cs_admin && infurnia_teams && infurnia_teams.length > 0 && added_cs_users && added_cs_users.length > 0) {
			let team_members = []
			infurnia_teams.map(team => {
				if(team.team_lead_id === user_details.id){
					team_members = team_members.concat(team.users)
				}
			})
			if(!team_members.includes(user_details.id)){
				team_members.push(user_details.id)
			}
			set_added_cs_users(added_cs_users.filter(o => team_members.includes(o.user_id)))
		}
	}, [is_cs_admin, is_team_lead, infurnia_teams, added_cs_users, user_details]);

	useEffect(() => {
		let from_date = new Date(), to_date = new Date()
		from_date.setDate(from_date.getDate() - 7)
		fetch_mix_panel_data(from_date, to_date, set_mixpanel_org_level_events)
	}, []);

	return(
		<>
			<Container fluid>
				<Row>
					<div className="col">
						<Card className="shadow">
							{
								<CardHeader className="border-4 bg-gradient-secondary">
									{
										is_cs_admin?
										<Row className='mb-3 mr-3' style={{ display: 'flex', justifyContent: 'end' }}><Button color='primary' onClick={() => {toggle_edit_modal(); set_mode('add')}}>Add CS Rep</Button></Row>:""
									}
								</CardHeader>
							}
							<div style={{minHeight:'200px'}}>
								<Table className="align-items-center table-flush text-center" responsive>
									<thead className="thead-light text-center">
										<tr style={{ textAlign: 'left' }}>
											<th scope="col"><span style={styles.table_header}><b>S.No</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Name</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Domain</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Expertise</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Geography</b></span>&nbsp;</th>
											<th scope="col"><span style={styles.table_header}><b>Load <i className="fa fa-sort" onClick={() => sort_by_load()} /></b></span>&nbsp;</th>
											{is_cs_admin ? <th scope="col"><span style={styles.table_header}><b>Actions</b></span>&nbsp;</th>  :''}
										</tr>
									</thead>
									<tbody>
										{
											added_cs_users && added_cs_users.length ? added_cs_users.map((user, idx) => (
												<tr className="display_onhover" style={{ textAlign: 'left' }}>
													<td scope="col"><span>{idx + 1}</span>&nbsp;</td>
													<td scope="col"><span>{user.first_name ? user.first_name : 'N/A'}</span>&nbsp;</td>
													<td scope="col"><span>{user.domain ? convert_to_readable(user.domain) : 'N/A'}</span>&nbsp;</td>
													<td scope="col">
														<Tooltip placement="bottom" title={user.expertise ? user.expertise.map((c, idx) => convert_to_readable(c) + (idx != user.expertise.length-1 ? ', ' : '')).join(' ') : 'N/A'}>
															<div className=" lines1_elipsis" style={{maxWidth:'300px',}}>
																{user.expertise ? user.expertise.map((c, idx) => convert_to_readable(c) + (idx != user.expertise.length-1 ? ', ' : '')).join(' ') : 'N/A'}
															</div>
														</Tooltip>
													</td>
													<td scope="col">
														<div className=" lines1_elipsis" style={{maxWidth:'300px',}}>
															{user.geographies ? user.geographies.map((c, idx) => (geographies && geographies.length ? geographies.find(o => o.id===c).name : '') + (idx != user.geographies.length-1 ? ', ' : '')).join(' ') : 'N/A'}
														</div>
													</td>
													<td className="flex_center" style={{paddingTop:'22px'}} scope="col">{ user.load } <i style={{fontSize:'16px'}} className="fa fa-info-circle invisible1 ml-2 cp" onClick={() => {set_active_cs(user) ;set_show_cs_load_modal(true)}} />&nbsp;</td>
													{
													is_cs_admin ?  
														<td scope="col">
															<i onClick={() => { set_active_cs(user); toggle_edit_modal(); set_mode('edit')}} style={{fontSize:'16px'}} className="fa fa-edit p-2  cp invisible1"></i>
														</td>:""
													}
												</tr>
											)) : ''
										}
									</tbody>
								</Table>
							</div>
						</Card>
					</div>
				</Row>
				{/* <AddCsExecutive open={add_modal_open} toggle={toggle_add_modal} users={remaining_cs_users}/> */}
				<EditCsExecutive open={edit_modal_open} toggle={() => {toggle_edit_modal(); set_mode('')}} active_cs={active_cs} geographies={geographies} remaining_cs_users={remaining_cs_users} mode={mode} fetch_cs_users={fetch_cs_org_users}/>
				<CsLoad open={show_cs_load_modal} toggle={() => set_show_cs_load_modal(false)} active_cs={active_cs} all_stores={all_stores} mixpanel_org_level_events={mixpanel_org_level_events}/>
			</Container>
		</>
	)
}

export default CSExecutives