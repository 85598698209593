import React, { useState,useEffect, useMemo, useCallback } from "react";
import ISearch from "../../components/ISearch";
import { Row, Button, Input, DropdownItem, DropdownMenu, DropdownToggle, Dropdown, UncontrolledDropdown, Nav, NavItem, NavLink } from "reactstrap";
import AddCustomerTicket from "./AddCustomerTicket";
import general_fetch from "../../utils/fetch";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { convert_to_readable } from "../../utils/convert_to_readable";
import { shorten_text } from "../../utils/shorten_text";
import { date_string } from "../../utils/date_string";
import { DatePicker, Switch, Button as AntdButton, Radio, ConfigProvider } from 'antd';
import {EditCustomerTicket, check_ticket_edit_access} from "./EditCustomerTicket";
import { toastr } from "react-redux-toastr";
import Select from 'react-select'
import { colourStyles, colourStyles4 } from "../../utils/react_select_styles";
import generic_sort from "../../utils/generic_sort";
import { customer_tickets_status_options } from "../../utils/customer_tickets_status_options";
import { impact_area_options } from "../../utils/cs_ticket_impact_area";
import makeAnimated from 'react-select/animated';
import { confirmAlert } from "react-confirm-alert";
import DeleteNotification from "../../components/DeleteNotification";

const submit = (yes_del, no_del, message, button_name, Custom_modal) => {
	confirmAlert({
	  title: 'Confirm to submit',
	  message: message,
	  customUI: ({ onClose }) => {
		return (
		  Custom_modal ?
			<div>
			  <Custom_modal yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
			:
			<div className='custom-ui'>
			  <DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
		);
	  }
	});
}

const animatedComponents = makeAnimated()

const sort_by_options = [{value:'none', label:'None'}, 
						{value:'created_at_low_to_high', label:'Created At (Early first)'},
						{value:'created_at_high_to_low', label:'Created At (Latest first)'}, 
						{value:'priority_low_to_high', label:'Priority (Low to High)'}, 
						{value:'priority_high_to_low', label:'Priority (High to Low)'}, 
						{value:'customer_priority_low_to_high', label:'Customer Priority (Low to High)'}, 
						{value:'customer_priority_high_to_low', label:'Customer Priority (High to Low)'}]

const get_cs_org_user_map = (cs_org_users) => {
    let cs_org_user_map = {};
    for(let user of cs_org_users) {
        cs_org_user_map[user.id] = user;
    }
    return cs_org_user_map;
}

const CustomerTickets = ({user_details, cs_org_users, all_stores, store_id, set_page_loader}) => {
	const [search_string, set_search_string] = useState('');
	const [mode, set_mode] = useState('');
	const [show_add_edit_customer_ticket, set_show_add_edit_customer_ticket] = useState(false);
	const [active_ticket, set_active_ticket] = useState();
	const [customer_tickets, set_customer_tickets] = useState([]);
	const [display_customer_tickets, set_display_customer_tickets] = useState([]);
	const [page_size, set_page_size] = useState(50);
	const [initialized, set_initialized] = useState(false);
	const [description_expand_json, set_description_expand_json] = useState({});
	const [show_edit_drawer, set_show_edit_drawer] = useState(false);
	const [selected_ticket, set_selected_ticket] = useState();
	const [sort_by, set_sort_by] = useState('created_at_low_to_high');
	const [status_filter, set_status_filter] = useState([]);
	const [org_filter, set_org_filter] = useState([]);
    const [assignee_filter, set_assignee_filter] = useState([]);
    const [date_filter, set_date_filter] = useState([]);
    const [gchat_post_filter, set_gchat_post_filter] = useState(null);
    const [linked_jira_filter, set_linked_jira_filter] = useState(null);
    const [following_ticket_filter, set_following_ticket_filter] = useState([]);
    const [impact_area_filter, set_impact_area_filter] = useState([]);
	const [dropdownOpen, setDropdownOpen] = useState({});
    const [show_archive_only_ticket, set_show_archive_only_ticket] = useState(false);
	const [selectedNavItem, set_selectedNavItem] = useState(0);
    const [assignee_filter_options, set_assignee_filter_options] = useState();
    const [team_info, set_team_info] = useState();
    const [total_viewing_ticket_count, set_total_viewing_ticket_count] = useState(0);

    const filter_archived = (tickets) => {
        let t = show_archive_only_ticket ? tickets.filter(x => x.archived) : tickets.filter(x => !x.archived);
        return t;
    }

	const fetch_customer_tickets = async() => {
		try{
            if(user_details && user_details.role && user_details.role.store && user_details.role.store.length) {
                let [resp, teams] = await Promise.all([
                    general_fetch({url:'customer_ticket/get_all'}),
                    general_fetch({url: 'team/get'})
                ])
				teams = teams.filter(o => o.type === 'customer_success')
                if(store_id) {
                    set_org_filter([{value: store_id, label: ''}]);
                    set_selectedNavItem(2)
                }
                // if(user_details.role.store.includes('customer_success_admin')) {
                //     set_selectedNavItem(2)
                // }
                let team_info_map = {};
                for(let team of teams) {
                    let team_users = [team.team_lead_id];
                    for(let user of team.users) {
                        team_users.push(user);
                    }
                    for(let user of team.users) {
                        team_info_map[user] = {members: team_users, team_lead: team.team_lead_id}
                    }
                    team_info_map[team.team_lead_id] = {members: team_users, team_lead: team.team_lead_id}
                }
                set_team_info(team_info_map)
                set_customer_tickets(resp)
            }
			set_initialized(true)
		}catch(err){
			set_initialized(true)
			console.error(err)
		}
	}

	const update_client_notification = async(customer_ticket_id, client_notification) => {
		try{
			let resp = await general_fetch({url:'customer_ticket/update', body:{customer_ticket_id, client_notification}})
			fetch_customer_tickets()
			toastr.success('Client notification '+(client_notification?'enabled':'disabled'))
		}catch(err){
			toastr.error(`Coudln't update client notification settings`)
			console.error(err)
		}
	}

	const update_status = async(customer_ticket_id, status) => {
		try{
			let resp = await general_fetch({url:'customer_ticket/update', body:{customer_ticket_id, status}})
			fetch_customer_tickets()
			toastr.success('Successfully updated ticket status')
		}catch(err){
			toastr.error(`Coudln't update ticket status`)
			console.error(err)
		}
	}

    const update_ticket_archive_status = async (customer_ticket_id, archive_status) => {
        try {
			let fd = new FormData()
            fd.append('archived', archive_status)
			fd.append('customer_ticket_id', customer_ticket_id)
            let resp = await general_fetch({url: 'customer_ticket/update', body: fd, is_form_data: true});
            fetch_customer_tickets()
            toastr.success(`Successfully ${archive_status ? '' : 'un-'}archived the ticket`);
        } catch(err) {
            toastr.error(`Couldn't ${archive_status ? '' : 'un-'}archive the ticket`);
            console.error(err);
        }
    }

    const delete_customer_ticket = async (customer_ticket_id) => {
        try {
            let resp = await general_fetch({url: 'customer_ticket/delete_ticket', body: {customer_ticket_id}});
            fetch_customer_tickets()
            toastr.success(`Successfully deleted the ticket`);
        } catch(err) {
            toastr.error(`Couldn't delete the ticket`);
            console.error(err);
        }
    }

    const follow_ticket = async ({customer_ticket_id}) => {
		try{
			let resp = await general_fetch({url:'customer_ticket/follow_ticket', body:{customer_ticket_id, follower_id: user_details.id}});
			toastr.success('Successfully followed ticket')
			fetch_customer_tickets()
		}catch(err){
			console.error(err)
			toastr.error(`Couldn't follow ticket`)
		}
    }

    const unfollow_ticket = async ({customer_ticket_id}) => {
		try{
			let resp = await general_fetch({url:'customer_ticket/unfollow_ticket', body:{customer_ticket_id, follower_id: user_details.id}});
			toastr.success('Successfully unfollowed ticket')
			fetch_customer_tickets()
		}catch(err){
			console.error(err)
			toastr.error(`Couldn't unfollow ticket`)
		}
    }

	const filter_fn = () => {
		let temp_tickets = JSON.parse(JSON.stringify(customer_tickets))

        if(selectedNavItem == 0) {
            temp_tickets = temp_tickets.filter(x => x.creator_id == user_details.id)
        } else if(selectedNavItem == 1) {
            if(team_info) {
                let this_team = team_info[user_details.id] ? team_info[user_details.id] : {members: []};
                temp_tickets = temp_tickets.filter(x => this_team.members.includes(x.creator_id));
            }
        }
        temp_tickets = filter_archived(temp_tickets);
        set_total_viewing_ticket_count(temp_tickets.length);

		if(org_filter && org_filter.length){
			let org_ids = org_filter.map(x => x.value)
			temp_tickets = temp_tickets.filter(x => org_ids.includes(x.org_id))
		}

		if(search_string){
			temp_tickets = temp_tickets.filter(x => (x.title && x.title.toLowerCase().includes(search_string.toLowerCase())) || (x.description && x.description.toLowerCase().includes(search_string.toLowerCase())))
		}

		if(status_filter && status_filter.length){
			let statuses = status_filter.map(x => x.value)
			temp_tickets = temp_tickets.filter(x => statuses.includes(x.status))
		}

        if(assignee_filter && assignee_filter.length) {
            let assignees = assignee_filter.map(x => x.value)
			temp_tickets = temp_tickets.filter(x => assignees.includes(x.creator_id))
        }

        if(date_filter && date_filter.length && date_filter[0] && date_filter[1]) {
			temp_tickets = temp_tickets.filter(x => 
                new Date(x.created_at) > new Date(date_filter[0]) && new Date(x.created_at) < new Date(date_filter[1])
            )
        }

        if(gchat_post_filter) {
            let value = gchat_post_filter.value;
            if(value == "escalated") {
                temp_tickets = temp_tickets.filter(x => x.cs_team_escalations_thread);
            } else if(value == "doubt") {
                temp_tickets = temp_tickets.filter(x => x.cs_team_doubts_thread);
            } else if(value == "none") {
                temp_tickets = temp_tickets.filter(x => !x.cs_team_doubts_thread).filter(x => !x.cs_team_escalations_thread);
            }
        }

        if(linked_jira_filter && linked_jira_filter.length) {
            let jira_status = linked_jira_filter.map(x => x.value)
            temp_tickets = temp_tickets.filter(x => jira_status.includes(x.back_office_ticket && x.back_office_ticket.jira_id != null ? 1 : 0))
        }

        if(following_ticket_filter && following_ticket_filter.length) {
            let values = following_ticket_filter.map(x => x.value)
            temp_tickets = temp_tickets.filter(x => values.includes(x.followers && x.followers.includes(user_details.id) ? 1 : 0))
        }

        if(impact_area_filter && impact_area_filter.length) {
            let values = impact_area_filter.map(x => x.value)
            temp_tickets = temp_tickets.filter(x => values.includes(x.impact_area))
        }

		sort_fn(temp_tickets)
	}

	const sort_fn = (tickets) => {
		let temp_tickets = tickets ? tickets : JSON.parse(JSON.stringify(display_customer_tickets));
		let null_tickets = []
		let field = null
		let order = 1
		if(sort_by.includes('customer_priority')){
			field = 'customer_priority'
		}else if(sort_by.includes('priority')){
			field = 'priority'
		}else if(sort_by.includes('created_at')){
			field = 'created_at'
		}
        if(field) {
            if(sort_by.includes('low_to_high')) order = -1
            if(field === 'priority' || field === 'customer_priority'){
                null_tickets = temp_tickets.filter(x => !x[field])
                temp_tickets = temp_tickets.filter(x => x[field])
            }
            if(field != "created_at") {
                temp_tickets.sort((a,b) => generic_sort(a, b, field, order, 'number'))
            } else {
                temp_tickets.sort((a,b) => generic_sort(a, b, field, order, 'date'))
            }
            temp_tickets = [...temp_tickets, ...null_tickets]
        }
		set_display_customer_tickets(temp_tickets)
	}

	const onChangeDate = (date, dateString) => {
        set_date_filter(dateString)
		// document.getElementById('filter_date').click()
	}

	const trigger_gchat_notification = async(customer_ticket_id, channel_name) => {
		try {
			let resp = await general_fetch({url:'customer_ticket/trigger_gchat_notification', body:{customer_ticket_id, channel_name}})
			fetch_customer_tickets()
			toastr.success(`Successfully posted to ${convert_to_readable(channel_name)} space`)
		}catch(err) {
			toastr.error(`Couldn't post to gchat`)
			console.error(err)
		}
	}

	useEffect(() => {
		fetch_customer_tickets()
		if(document.getElementById('customer_tickets_header') && document.getElementById('customer_tickets_table')){
			document.getElementById('customer_tickets_table').style.height = window.innerHeight - document.getElementById('customer_tickets_header').offsetHeight + 'px'
		}
	}, []);

	useEffect(() => {
		let currentURL = new URL(document.URL)
		if(currentURL.searchParams.get('id')){
			set_show_edit_drawer(true)
			set_selected_ticket({id: currentURL.searchParams.get('id')})
		}
	}, []);

	useEffect(() => {
		if(display_customer_tickets && display_customer_tickets.length) sort_fn()
	}, [sort_by,]);

	useEffect(() => {
		filter_fn()
	}, [customer_tickets, search_string, status_filter, org_filter, assignee_filter, date_filter, show_archive_only_ticket, gchat_post_filter, linked_jira_filter, following_ticket_filter, impact_area_filter, selectedNavItem]);

	useEffect(() => {
		let currentURL = new URL(document.URL)
		if(show_edit_drawer && selected_ticket && currentURL.searchParams.get('id')!=selected_ticket.id){
			currentURL.searchParams.set('id', selected_ticket.id)
			let updatedSearchString = currentURL.searchParams.toString();
			let newUrl = window.location.pathname + '?' + updatedSearchString;
			window.history.pushState({ path: newUrl }, '', newUrl);
		}else if(!show_edit_drawer){
			let newUrl = window.location.pathname ;
			window.history.pushState({ path: newUrl }, '', newUrl);
		}
	}, [show_edit_drawer, selected_ticket]);

    useEffect(() => {
        if(cs_org_users && cs_org_users.length && customer_tickets && customer_tickets.length) {
            let cs_user_map = get_cs_org_user_map(cs_org_users);
            let creator_ids = Array.from(new Set(customer_tickets.map(x => x.creator_id)));
            let options = creator_ids.map(x => {return {value: x, label: cs_user_map[x].first_name + ' ' + cs_user_map[x].last_name}});
            set_assignee_filter_options(options);
        }
    }, [cs_org_users, customer_tickets])

	return (
		<div>
        <ConfigProvider theme={{token: {colorPrimary: '#5e72e4'}}}>
			<EditCustomerTicket open={show_edit_drawer} toggle={() => {set_show_edit_drawer(false); set_selected_ticket('')}} all_orgs={all_stores} selected_ticket_id={selected_ticket ? selected_ticket.id : ''} fetch_customer_tickets={fetch_customer_tickets} set_page_loader={set_page_loader} cs_org_users={cs_org_users} user_details={user_details} team_info={team_info}/>
			<div id='customer_tickets_header'>
				<div className="flex_center px-4 pt-3" style={{justifyContent:'space-between'}}>
					<div className="flex_property">
                        {
                            !store_id ? <>
                                <Nav pills style={{ paddingBottom: 5, marginTop: 4 }}>
                                    <NavItem>
                                        <NavLink href='#' active={selectedNavItem === 0} onClick={() => { set_selectedNavItem(0); }}><b>My Issues</b> </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href='#' active={selectedNavItem === 1} onClick={() => { set_selectedNavItem(1); }}><b>Team Issues</b> </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href='#' active={selectedNavItem === 2} onClick={() => { set_selectedNavItem(2); }}><b>All Issues</b> </NavLink>
                                    </NavItem>
                                </Nav>
                            </> : <b>Customer Tickets ({display_customer_tickets.length})</b> 
                        }
                        <div className="ml-6 flex_property">
                            <Radio.Group size="medium" options={[{label: 'Active', value: false}, {label: 'Archive', value: true}]} value={show_archive_only_ticket} onChange={({target: {value}}) => {set_show_archive_only_ticket(value)}} optionType="button" buttonStyle="solid" />
                            <div style={{ fontSize: '14px', color: 'purple' }} className="ml-4">Viewing {display_customer_tickets.length} of {total_viewing_ticket_count} Tickets</div>
                        </div>
                    </div>
					<div className="flex_center">
						<ISearch search_text={search_string} set_search_text={set_search_string}/>
						<Button color="primary" onClick={() => {set_show_add_edit_customer_ticket(true); set_mode('add')}}>Create Ticket</Button>
					</div>
				</div>
			</div>
			<div className="mx-2 px-4 pt-4" id='customer_tickets_table' style={{overflow:'auto', position:'relative'}}>
				<div className="flex_between mb-3" style={{fontSize:'14px'}}>
					<div className="flex_center">
						<div className="mr-2" style={{fontSize:'13px'}}>Status:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={status_filter}
								isMulti
								options={customer_tickets_status_options}
								styles={colourStyles}
								onChange={(selectedOptions) => set_status_filter(selectedOptions)}
							/>
						</div>
                        {
                            !store_id ?
                            <>
                                <div className="mr-2" style={{fontSize:'13px'}}>Org:</div>
                                <div className="mr-2" style={{fontSize:'12px'}}>
                                    <Select
                                        // closeMenuOnSelect={false}
                                        // components={animatedComponents}
                                        value={org_filter}
                                        isMulti
                                        options={all_stores && all_stores.length ? all_stores.map(x => { return { value: x.id, label: (x.name + ' (' + x.id + ')') } }) : []}
                                        styles={colourStyles}
                                        onChange={(selectedOptions) => set_org_filter(selectedOptions)}
                                    />
                                </div>
                            </>:''
                        }
                        {
                            selectedNavItem != 0 ? <>
                            <div className="mr-2" style={{fontSize:'13px'}}>Assignee:</div> 
                            <div className="mr-2" style={{fontSize:'12px'}}>
                                <Select
                                    // closeMenuOnSelect={false}
                                    // components={animatedComponents}
                                    value={assignee_filter}
                                    isMulti
                                    options={assignee_filter_options && assignee_filter_options.length ? assignee_filter_options: []}
                                    styles={colourStyles}
                                    onChange={(selectedOptions) => set_assignee_filter(selectedOptions)}
                                />
                            </div>
                            </> : ''
                        }
                        <div className="mr-2" style={{fontSize:'13px'}}>Date:</div> 
                        <div className="mr-2" style={{fontSize:'12px'}}>
                            <DatePicker.RangePicker
								id = 'filter_date'
                                format="YYYY-MM-DD"
                                onChange = {onChangeDate}
                                // defaultValue = {from_date}
                                // disabledDate={(d) => new Date(d) < new Date(today)}
                            />
                        </div>
					</div>
					<div className="flex_center">
						<div className="mr-2" style={{fontSize:'13px'}}>Sort by:</div>
						<div style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={{value:sort_by, label:sort_by_options.find(o => o.value === sort_by) ? sort_by_options.find(o => o.value === sort_by).label : ''}}
								// isMulti
								options={sort_by_options}
								styles={colourStyles}
								onChange={(selectedOptions) => set_sort_by(selectedOptions.value)}
							/>
						</div>
					</div>
				</div>
				<div className="flex_between mb-3" style={{fontSize:'14px', position: 'relative', zIndex: 1}}>
					<div className="flex_center">
						<div className="mr-2" style={{fontSize:'13px'}}>Escalation Status:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={gchat_post_filter}
								// isMulti
								options={[{value: 'escalated', label: 'Escalated'}, {value: 'doubt', label: 'Discussed'}, {value: 'none', label: 'None'}]}
								styles={colourStyles}
								onChange={(selectedOptions) => set_gchat_post_filter(selectedOptions)}
							/>
						</div>
						<div className="mr-2" style={{fontSize:'13px'}}>Linked Jira:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={linked_jira_filter}
								isMulti
								options={[{value: 1, label: "Yes"}, {value: 0, label: "No"}]}
								styles={colourStyles}
								onChange={(selectedOptions) => set_linked_jira_filter(selectedOptions)}
							/>
						</div>
						<div className="mr-2" style={{fontSize:'13px'}}>Following:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={following_ticket_filter}
								isMulti
								options={[{value: 1, label: "Yes"}, {value: 0, label: 'No'}]}
								styles={colourStyles}
								onChange={(selectedOptions) => set_following_ticket_filter(selectedOptions)}
							/>
						</div>
						<div className="mr-2" style={{fontSize:'13px'}}>Impact area:</div>
						<div className="mr-2" style={{fontSize:'12px'}}>
							<Select
								// closeMenuOnSelect={false}
								// components={animatedComponents}
								value={impact_area_filter}
								isMulti
								options={impact_area_options.map(x => x.options).flat()}
								styles={colourStyles}
								onChange={(selectedOptions) => set_impact_area_filter(selectedOptions)}
							/>
						</div>
					</div>
				</div>
			{
				display_customer_tickets && display_customer_tickets.length ? display_customer_tickets.map((single_ticket, idx) => (
					<div key={single_ticket.id} className="ticket_div activity_box mb-4" style={{padding:'0px'}} onClick={() => {}} >
						<div style={{padding:"18px 18px 10px 18px"}}>
							<div className="flex_between mb-2" style={{fontSize:'16px'}}>
								<div className="flex_property">
									<i className="fa fa-ticket mr-2" style={{fontSize:'20px', color:'#A799B7'}} />
									<div className="cp tickets_title" onClick={(e) => {set_show_edit_drawer(true); set_selected_ticket(single_ticket)}}>{single_ticket.title}</div>
								</div>
								<div className="flex_property">
									{
										single_ticket.customer_id?
										<div className="flex_property mr-2">
											<div style={{fontSize:'13px'}} onClick={(e) => e.stopPropagation()} className="mr-2 flex_property">
												<div className="mr-1">Client Notification</div> <Switch size="small" checked={single_ticket.client_notification} onClick={() => update_client_notification(single_ticket.id, !single_ticket.client_notification)} />
											</div>
										</div>:''
									}
                                    {
                                        single_ticket && !single_ticket.followers.includes(user_details.id) ?
                                            <div>
                                                <AntdButton type="link" className="flex_property archive" onClick={(e) => {submit(()=> follow_ticket({customer_ticket_id: single_ticket.id}), ()=>{}, `Follow the ticket to receive updates on Google Chat`, '', '')}}>
                                                    <i className="fas fa-solid fa-eye" style={{ fontSize: '16px', color: '#32325d' }}/>
                                                </AntdButton>
                                            </div>:''
                                    }
                                    {
                                        single_ticket && single_ticket.followers.includes(user_details.id) ?
                                            <div>
                                                <AntdButton type="link" className="flex_property archive" onClick={(e) => {submit(()=> unfollow_ticket({customer_ticket_id: single_ticket.id}), ()=>{}, `Un-Follow the ticket`, '', '')}}>
                                                    <i className="fas fa-solid fa-eye-slash" style={{ fontSize: '16px', color: '#32325d' }}/>
                                                </AntdButton>
                                            </div>:''
                                    }
                                    {
                                        single_ticket && check_ticket_edit_access({user_details, selected_ticket: single_ticket, team_info}) && !single_ticket.archived ?
                                            <>
                                            <AntdButton type="link" className="flex_property archive" onClick={(e) => {submit(()=> update_ticket_archive_status(single_ticket.id, true), ()=>{}, `This action will archive the CS Ticket`, '', '')}}>
                                                <i className="fas fa-solid fa-archive" style={{ fontSize: '16px', color: '#32325d'  }}/>
                                            </AntdButton>
                                            </>:''
                                    }
                                    {
                                        single_ticket && check_ticket_edit_access({user_details, selected_ticket: single_ticket, team_info}) && single_ticket.archived ?
                                            <>
                                            <AntdButton type="link" className="flex_property archive" onClick={(e) => {submit(()=> update_ticket_archive_status(single_ticket.id, false), ()=>{}, `This action will un-archive the CS Ticket`, '', '')}}>
                                                <i className="fas fa-solid fa-window-restore" style={{ fontSize: '16px', color: '#32325d'  }}/>
                                            </AntdButton>
                                            </>:''
                                    }
                                    {
                                        single_ticket && check_ticket_edit_access({user_details, selected_ticket: single_ticket}) ?
                                            <div>
                                                <AntdButton type="link" className="flex_property" onClick={(e) => {submit(()=> delete_customer_ticket(single_ticket.id), ()=>{}, `This action will Delete the Customer Ticket`, '', '')}}>
                                                    <i className="fas fa-solid fa-trash" style={{ fontSize: '16px', color: '#f02222' }}/>
                                                </AntdButton>
                                            </div>:''
                                    }
									<UncontrolledDropdown >
										<DropdownToggle  style={{padding:'4px 0px 4px 4px', background:'unset', boxShadow:'unset', border:'unset'}}>
											<i className="fas fa-ellipsis-h cp" style={{fontSize: '14px'}} ></i>
										</DropdownToggle>
										<DropdownMenu>
											<DropdownItem onClick={(e) => {set_show_edit_drawer(true); set_selected_ticket(single_ticket)}}>Edit</DropdownItem>
                                            {
                                                single_ticket.cs_team_doubts_thread ? 
                                                    <DropdownItem onClick={single_ticket.cs_team_doubts_thread ? null : (e) => {submit(() => trigger_gchat_notification(single_ticket.id, 'cs_team_doubts'), ()=>{}, `This action will post a message in "CS Team doubts" google chat space`, '','')}}>{single_ticket.cs_team_doubts_thread ? <a href={single_ticket.cs_team_doubts_thread.replace(/spaces\/([^/]+)\/threads\/([^/]+)/, "https://chat.google.com/room/$1/$2/$2")} target="_blank">View 'CS Team doubts' thread</a> : "Post to 'CS Team doubts'"}</DropdownItem>
                                                    : ''
                                            }
                                            {
                                                <DropdownItem onClick={single_ticket.cs_team_escalations_thread ? null : (e) => {submit(() => trigger_gchat_notification(single_ticket.id, 'cs_team_escalations'), ()=>{}, `This action will post a message in "CS Team escalations" google chat space`, '','')}}>{single_ticket.cs_team_escalations_thread ? <a href={single_ticket.cs_team_escalations_thread.replace(/spaces\/([^/]+)\/threads\/([^/]+)/, "https://chat.google.com/room/$1/$2/$2")} target="_blank">View 'CS Team escalations' thread</a> : "Post to 'CS Team escalations'"}</DropdownItem>
                                            }
										</DropdownMenu>
									</UncontrolledDropdown>
									
								</div>
							</div>
							<div style={{fontSize:'12px', marginLeft:'28px'}} className="flex_between">
								<div className="flex_property">
									{
										single_ticket.org_id?
										<div className="mr-2 flex_property">
											<Link to={`/home/store_dashboard/${single_ticket.org_id}/7`} onClick={(e) => e.stopPropagation()} style={{textDecoration:'underline'}} className="mr-2">{single_ticket.org_id && all_stores && all_stores.length && all_stores.find(o => o.id===single_ticket.org_id) ? all_stores.find(o => o.id===single_ticket.org_id).name : ''}</Link>
											|
										</div>:''
									}
									{
										single_ticket.priority?
										<div className="mr-2 flex_property">
											<div className="mr-2">
											Priority - {single_ticket.priority}
											</div>
											|
										</div>:""
									}
									{
										single_ticket.customer_priority?
										<div className="mr-2 flex_property">
											<div className="mr-2">
												Customer Priority - {single_ticket.customer_priority}
											</div>
											|
										</div>:""
									}
                                    {
                                        // single_ticket.back_office_ticket && single_ticket.back_office_ticket.jira_key ?
                                        // <div className="mr-2 flex_property" onClick={(e) => e.stopPropagation()}>
                                        //     <a href={`https://infurniacoretech.atlassian.net/jira/software/c/projects/PT/issues/${single_ticket.back_office_ticket.jira_key}`} target="_blank" className="mr-2 flex_property" style={{textDecoration:'underline', color:'#0078ff'}}><img src='/jira.svg' height={'14px'} width={'14px'} className="mr-1"/><div>{single_ticket.back_office_ticket.jira_key}</div></a>
                                        //     |
                                        // </div>:""
                                    }
                                    {
                                        // single_ticket.back_office_ticket ?
                                        // <div className="mr-2 flex_property" onClick={(e) => e.stopPropagation()}>
                                        //     <a href={`${global.config.csp_path}/home/back_office_tickets?id=${single_ticket.back_office_ticket.id}`} target="_blank" className="mr-2 flex_property" style={{textDecoration:'underline', color:'#0078ff'}}><div>{shorten_text(single_ticket.back_office_ticket.title)}</div></a>
                                        //     |
                                        // </div>:""
                                    }
									{
										single_ticket.impact_area?
										<div className="mr-2 flex_property">
											<div className="mr-2">
											{single_ticket.impact_area}
											</div>
											|
										</div>:""
									}
                                    <div className="mr-2 flex_property">
                                        <div className="mr-2" style={{opacity:'0.5'}}>
                                            <i style={{color:''}} className="fa fa-history mr-1" />
                                            Created on {date_string(single_ticket.created_at)}
                                        </div>
                                        |
                                    </div>
									<div className="mr-2" style={{opacity:'0.5'}}>
										<i style={{color:''}} className="fa fa-history mr-1" />
										Updated on {date_string(single_ticket.updated_at)}
									</div>
								</div>
								<Input onClick={(e) => e.stopPropagation()} type='select' value={single_ticket.status} onChange={(e) => update_status(single_ticket.id, e.target.value)} style={{width:'150px', height:'32px', padding:'4px 12px', }}>
								{
									customer_tickets_status_options.map(x => (
										<option value={x.value} key={x.value}>{x.label}</option>
									))
								}
								</Input>
							</div>
						</div>
						{/* <hr className="mt-2 mb-3"/> */}
						<div className="cp" style={{fontSize:'13px', borderRadius:'6px', backgroundColor:/*'#F4F3EE'*/'#FFF8F0', padding:'6px 10px 6px 18px', display:'flex', justifyContent:'space-between'}} onClick={(e) => {e.stopPropagation(); description_expand_json[single_ticket.id] = !description_expand_json[single_ticket.id]; set_description_expand_json(JSON.parse(JSON.stringify(description_expand_json)))}}>
							<div style={{ borderRadius:'6px', flex:1, overflow:description_expand_json[single_ticket.id] ? '' : 'hidden', whiteSpace:description_expand_json[single_ticket.id] ? '' : 'nowrap', textOverflow:'ellipsis'}} >{single_ticket.description ? single_ticket.description : 'No description'}</div>
							<img src={`/svgs/${description_expand_json[single_ticket.id] ? 'arrows_converging.svg' : 'arrows_up_down.svg'}`}  style={{height:'18px', width:'18px'}} className="cp mr-1"/>
						</div>
					</div>
				)):<div className="flex_center" style={{fontWeight:700}}>{initialized ? store_id ? 'Org has no tickets' : 'You currently have no assigned tickets.' : 'Fetching Customer Tickets...'}</div>
			}
			</div>
			<AddCustomerTicket 
				open={show_add_edit_customer_ticket}
				toggle={() => set_show_add_edit_customer_ticket(false)} 
				mode={mode} 
				active_ticket = {active_ticket} 
				cs_org_users={cs_org_users}
				user_details={user_details}
				all_orgs = {all_stores}
				set_page_loader={set_page_loader}
				fetch_customer_tickets={fetch_customer_tickets}
			/>
        </ConfigProvider>
		</div>
	)
}

export default CustomerTickets;